import React, { useEffect } from "react"
import { Link } from "gatsby"

import productsList from "../../data/Products.json"

import previousArrow from "../../assets/images/left-arrow.png"
import nextArrow from "../../assets/images/right-arrow.png"

const Product = ({ index }) => {
  let data = productsList[index];

  const isDisable = (data["productName"] === "Laser Tag" || data["productName"] === "Escape The Lava");

  function change(event, next) {
    let bt = event.target
    let nImages = data["productImage"].length
    let i = bt.attributes.getNamedItem("i").value
    if (next) {
      bt.attributes.getNamedItem("i").value = (
        (parseInt(i) + 1) %
        nImages
      ).toString()
      i = bt.attributes.getNamedItem("i").value
      // console.log(bt.parentNode.previousSibling.previousSibling)
      bt.parentNode.previousSibling.previousSibling.src =
        data["productImage"][parseInt(i)]
    } else {
      bt.attributes.getNamedItem("i").value = (
        (parseInt(i) - 1 + nImages) %
        nImages
      ).toString()
      i = bt.attributes.getNamedItem("i").value
      bt.parentNode.previousSibling.src = data["productImage"][parseInt(i)]
    }
  }

  function productMedia(srcName) {
    console.log(srcName)
    if (srcName.indexOf("mp4") == -1) {
      return <img src={srcName} />
    } else {
      return (
        <video muted autoplay loop>
          <source type="video/mp4" src={srcName} />
        </video>
      )
    }
  }

  {
    /*if (index % 2 == 1) {*/
  }
  if (0) {
    return (
      <div className="product">
        <div className="productImage right">
          {/*<img
            src={previousArrow}
            className="changeImageButton"
            onClick={event => change(event, false)}
            i="0"
          />*/}
          <img src={data["productImage"][0]} />
          {/*<img
            src={nextArrow}
            className="changeImageButton"
            onClick={event => change(event, true)}
            i="0"
          />*/}
        </div>
        <div className="productDescription left">
          <h1>{data["productName"]}</h1>
          <h3>{data["productDescription"]}</h3>
          <button className="detailsButton">
            <Link to={data["url"]}>Learn More &#8599;</Link>
          </button>
        </div>
      </div>
    )
  } else {
    return (
      <div className="product">
        <div className="productDescription right">
          <h1>
            {data["productName"].substring(0, data["productName"].indexOf(" "))}{" "}
            <span>{data["productName"].substring(data["productName"].indexOf(" ") + 1)}</span>
          </h1>
          <h3>{data["productDescription"]}</h3>
          <button className="detailsButton">
            {!isDisable ?
              <Link to={data["url"]}>Learn More</Link>
              :
              <Link style={{ cursor: `${isDisable ? "not-allowed" : ""}` }}>Learn More</Link>
            }
            <i></i>
          </button>
        </div>
        <div className="productImage left">
          {productMedia(data["productImage"][0])}
          <div className="prev-button">
            <img
              src={previousArrow}
              className="changeImageButton"
              onClick={event => change(event, false)}
              i="0"
            />
          </div>
          <div className="next-button">
            <img
              src={nextArrow}
              className="changeImageButton"
              onClick={event => change(event, true)}
              i="0"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Product